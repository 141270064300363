import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonPage,
  IonRow,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { lastValueFrom } from 'rxjs';

import { IState } from 'appRedux/createStore';
import { ICasinoSchema, ILinkPlayerInfo } from 'appRedux/models/casinoModels';
import { ICasinoLinkedAccount } from 'services/auth';
import useToast from 'common/reactHooks/useToast';
import { CasinoService } from 'services/casino';

import LinkPlayerCardModal from './LinkPlayerCardModal';
import LinkedCasinoCard from './components/LinkedCasinoCard';
import SmartLoading from 'components/SmartLoading';

export interface ICasinoCardData {
  casinoId: number;
  casinoName: string;
  displayName: string;
  playerCardDisplayName: string;
  contact: string;
  website: string;
  logos: {
    navBar: string;
    playerCardImg: string;
  };
  displayAddress: string;
  isActive?: boolean;
  playerId?: string;
}

export const MyPlayerCards: React.FC = () => {
  const casinoService = new CasinoService();
  const showToast = useToast();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [casinoList, setCasinosList] = useState<ICasinoSchema[]>([]);
  const [casinosWithPlayerCards, setCasinosWithPlayerCards] = useState<ICasinoCardData[]>([]);
  const [casinosWithoutPlayerCards, setCasinosWithoutPlayerCards] = useState<ICasinoCardData[]>([]);

  const [selectedCasino, setSelectedCasino] = useState<ICasinoSchema>();

  const linkedPlayerState = useSelector<IState>(
    (state) => state.app.persistedState.linkPlayerState
  ) as ILinkPlayerInfo;

  const [showLinkModal, closeLinkModal] = useIonModal(LinkPlayerCardModal, {
    onSubmitSuccess: () => onSubmitSuccess(),
    onCancel: () => onCancel(),
    casinoInfo: selectedCasino,
  });

  const onSubmitSuccess = () => {
    closeLinkModal();
  };

  const onCancel = () => {
    closeLinkModal();
  };

  const openLinkCardModal = (casinoInfo: ICasinoSchema) => {
    setSelectedCasino(casinoInfo);

    showLinkModal({
      backdropDismiss: true,
      cssClass: 'tour-modal',
    });
  };

  useEffect(() => {
    let isIgnore = false;

    const fetchCasinosData = async (): Promise<void> => {
      if (!isIgnore) {
        try {
          setIsLoading(true);

          const casinoData = await lastValueFrom(casinoService.GetAllCasinos());
          if (casinoData?.DataSet) {
            const isActiveCasinos = casinoData.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
            );

            setCasinosList(isActiveCasinos);
          }
        } catch (error) {
          showToast({
            type: 'danger',
            message: 'An error occurred while retrieving the casino list',
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    void fetchCasinosData();

    return () => {
      isIgnore = true;
    };
  }, []);

  useEffect(() => {
    const combinedData = casinoList.map((casino) => {
      const casinoLinkedAccounts = linkedPlayerState?.casinoLinkedAccounts || [];
      const linkedAccount = casinoLinkedAccounts.find(
        (account: ICasinoLinkedAccount) => account.casinoId === casino.casinoId
      );

      return {
        casinoId: casino.casinoId,
        casinoName: casino.casinoName,
        displayName: casino.displayName,
        playerCardDisplayName: casino.playerCardDisplayName,
        contact: casino.contact,
        website: casino.website,
        logos: casino.logos,
        displayAddress: casino.address.displayAddress,
        isActive: casino.isactive,
        playerId: linkedAccount?.playerId,
      };
    });

    const foundCards = combinedData.filter((card) => card.playerId);
    const missingIdsCards = combinedData.filter((card) => !card.playerId);

    setCasinosWithPlayerCards(foundCards);
    setCasinosWithoutPlayerCards(missingIdsCards);
  }, [casinoList, linkedPlayerState]);

  return (
    <IonPage className="my-player-cards">
      <SmartLoading loading={isLoading} />

      <div className="slotcheck-header light-theme">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton
              fill="clear"
              onClick={() => history.goBack()}
              className="icon-back"
              slot="start"
            >
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>

            <div className="logo header-title">Profile</div>
          </IonToolbar>
        </IonHeader>
      </div>

      <IonContent>
        <div className="content light-theme">
          <div className="container light-theme">
            <IonGrid className="my-player-cards__container">
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <h1 className="remove-margins heading4 my-player-cards__container__heading">
                    My Player Cards
                  </h1>
                </IonCol>
              </IonRow>

              {casinosWithPlayerCards.length > 0 && (
                <IonRow data-testid="linkedCardSection">
                  <IonCol size="12" className="my-player-cards__container__section">
                    <h2 className="remove-margins body-bold my-player-cards__container__section__heading">
                      Registered Player IDs
                    </h2>

                    <ul className="unstyled-list list">
                      {casinosWithPlayerCards.map((casinoInfo) => (
                        <LinkedCasinoCard
                          key={casinoInfo.casinoId}
                          name={casinoInfo.displayName}
                          address={casinoInfo.displayAddress}
                          playerId={casinoInfo?.playerId || ''}
                        ></LinkedCasinoCard>
                      ))}
                    </ul>
                  </IonCol>
                </IonRow>
              )}

              {casinosWithoutPlayerCards.length > 0 && (
                <IonRow data-testid="noLinkCardSection">
                  <IonCol size="12" className="my-player-cards__container__section">
                    <h2 className="remove-margins body-bold my-player-cards__container__section__heading">
                      Add additional Player IDs
                    </h2>

                    <ul className="unstyled-list list">
                      {casinosWithoutPlayerCards.map((casinoInfo) => (
                        <LinkedCasinoCard
                          key={casinoInfo.casinoId}
                          name={casinoInfo.displayName}
                          address={casinoInfo.displayAddress}
                          onActionClick={() =>
                            openLinkCardModal(
                              casinoList.find(
                                (info) => info.casinoId === casinoInfo.casinoId
                              ) as ICasinoSchema
                            )
                          }
                        ></LinkedCasinoCard>
                      ))}
                    </ul>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPlayerCards;
