import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { differenceInYears, format, parseISO } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { lastValueFrom } from 'rxjs';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { AuthService } from 'services/auth';
import { IState } from 'appRedux/createStore';
import { onPromise } from 'pages/Signup/views/CreateAccount';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { PlayerService } from 'services/player';
import SmartLoading from 'components/SmartLoading';
import InfoWarning, { InfoWarningProps } from 'components/InfoWarning/InfoWarning';

interface LinkPlayerCardModalProps {
  casinoInfo: ICasinoSchema;
  onSubmitSuccess: () => void;
  onCancel: () => void;
}

interface FormData {
  playerId: string;
  birthday: string;
}

export const validatePlayerId = yup.object().shape({
  playerId: yup.string().required('Player ID is required'),
  birthday: yup
    .string()
    .test('dateOfBirth', 'You must be 18 years or older', (value) => {
      if (!value) {
        return false; // Fail validation if value is undefined or empty
      }

      const formattedDate = format(parseISO(value), 'yyyy-MM-dd');
      return differenceInYears(new Date(), new Date(formattedDate)) >= 18;
    })
    .required('Birthday is required'),
});

export const LinkPlayerCardModal: React.FC<LinkPlayerCardModalProps> = ({
  casinoInfo,
  onSubmitSuccess,
  onCancel,
}: LinkPlayerCardModalProps) => {
  const authService = new AuthService();
  const playerService = new PlayerService();

  const dispatch = useDispatch();
  const casinoCommands = CasinoReduxCommandCreator(dispatch);

  const [linkPlayerLoading, setLinkPlayerLoading] = useState(false);
  const [errorWarning, setErrorWarning] = useState<InfoWarningProps | null>(null);

  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;

  const {
    control,
    getValues,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(validatePlayerId),
  });
  const [presentToast] = useIonToast();

  const onSubmit = async (data: FormData): Promise<void> => {
    setLinkPlayerLoading(true);
    setErrorWarning(null);

    if (isValid) {
      try {
        const formattedDate = format(parseISO(data.birthday), 'yyyy-MM-dd').replace(/-/g, '-');

        await lastValueFrom(
          playerService.LinkPlayerCard(casinoInfo.casinoId, userId, data.playerId, formattedDate)
        ).then(async () => {
          const linkedResponse = await lastValueFrom(authService.GetLinkedAccounts(userId));

          const linkedAccounts = linkedResponse?.user.linkedAccounts;
          if (linkedAccounts) {
            casinoCommands.SetLinkedCasinos({
              casinoLinkedAccounts: linkedAccounts,
            });

            void presentToast({
              position: 'top',
              duration: 5000,
              color: 'success',
              message: `Successfully added your player ID for ${casinoInfo.displayName}.`,
            });
          }

          onSubmitSuccess();
        });
      } catch (error) {
        setErrorWarning({
          status: 'info',
          heading: 'Player ID and birthdate do not match',
          description:
            'Please try again. If the problem continues, visit the player desk for assistance.',
        });
      } finally {
        setLinkPlayerLoading(false);
      }
    }
  };

  return (
    <>
      <SmartLoading loading={linkPlayerLoading} />
      <IonModal id="dobpicker" keepContentsMounted={true}>
        <Controller
          render={({ field: { onChange } }) => (
            <IonDatetime
              value={getValues('birthday')}
              id="dobDate"
              presentation="date"
              preferWheel={true}
              showDefaultButtons={true}
              onIonChange={(e) => onChange(e.detail.value)}
            ></IonDatetime>
          )}
          control={control}
          name="birthday"
          rules={{ required: true }}
        />
      </IonModal>

      <IonCard
        id="link-player-card-modal"
        data-testid="linkCardModal"
        className="modal-container link-player-card-modal"
      >
        <IonCardContent>
          <div className="heading-container">
            <div role="heading" className="heading5 heading">
              Link Player ID
            </div>
            <div role="heading" className="subtitle2 subtitle">
              {casinoInfo.displayName}
            </div>
          </div>

          {errorWarning?.status && (
            <div className="error-card">
              <InfoWarning
                status={errorWarning.status}
                heading={errorWarning.heading}
                description={errorWarning.description}
              />
            </div>
          )}

          <form
            onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
            className="form"
          >
            <div className="fields-container">
              <IonRow>
                <IonCol className="ion-no-padding form-field player-id">
                  <div className="field">
                    <IonLabel className="body label">Player ID</IonLabel>

                    <IonItem className="input" fill="outline">
                      <IonInput
                        placeholder="Enter Player ID"
                        {...register('playerId', {
                          required: 'This is a required field',
                        })}
                      />
                    </IonItem>
                  </div>

                  {errors.playerId?.message && (
                    <div className="field-validation-message body" data-testid="playerId-error">
                      {errors.playerId.message}
                    </div>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-no-padding form-field">
                  <div className="field">
                    <IonLabel className="body label">Birthday</IonLabel>
                    <IonDatetimeButton
                      className="datetime"
                      datetime="dobDate"
                      data-testid="dob-button"
                    >
                      Dob
                    </IonDatetimeButton>
                  </div>

                  {errors.birthday?.message && (
                    <div className="field-validation-message body" data-testid="dob-error">
                      {errors.birthday.message}
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </div>

            <IonRow>
              <IonCol>
                <IonButton expand="block" color="primary" type="submit" data-testid="submitButton">
                  Link Player ID
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" color="medium" onClick={onCancel}>
                  Maybe Later
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default LinkPlayerCardModal;
