/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IonButton, IonImg } from '@ionic/react';
import { useHistory } from 'react-router';
import { IState } from 'appRedux/createStore';
import { useSelector } from 'react-redux';
import { useState } from 'react';

interface IPlans {
  onClickUpgrade: (couponCode: string) => void;
}

export const ProPlanLegacy = ({ onClickUpgrade }: IPlans) => {
  const history = useHistory();
  const [couponCode, setCouponCode] = useState('');
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;

  const onClickBack = () => {
    history.replace('/tabs/home');
  };
  return (
    <>
      <div className="slot-plan-box">
        <div className="header">
          <div style={{ marginBottom: '20px' }}>
            <IonImg style={{ height: '75px' }} src="assets/images/PRO-logo.svg" />
          </div>
          <div className="price-container">
            <span className="primary-color-text price heading6">FREE UPGRADE</span>{' '}
          </div>
        </div>
        <div className="slot-plan-box-content body-bold">
          <ul>
            <div>
              <li>Pro access to all Casinos available in SlotCheck</li>
            </div>
          </ul>
        </div>
        <div className="slot-plan-box-footer">
          <div className="slot-plan-box-footer">
            {isProUser && (
              <>
                <IonButton color="light" fill="outline" onClick={onClickBack}>
                  Active Plan
                </IonButton>
              </>
            )}

            <IonButton onClick={() => onClickUpgrade(couponCode)}>
              All
              <br /> Casino Upgrade!
            </IonButton>
          </div>
        </div>
      </div>
    </>
  );
};
