/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useState } from 'react';
import { IonButton, IonContent, IonHeader, IonImg, IonPage, IonToolbar } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import useToast from 'common/reactHooks/useToast';
import { StripeService } from 'services/stripe';
import useLocalStorage from 'common/reactHooks/useLocalStorage';
import { IState } from 'appRedux/createStore';
import { ICasinoSchema } from 'appRedux/models/casinoModels';

import { ProPlanLegacy } from './views/proPlanLegacy';

interface IStripe {
  redirectToCheckout(post: { sessionId: string }): any;
}

interface ILocationWithPreviousPage {
  previousPage?: string;
}

const slotCheckCasinoId = __SLOTCHECK_ID__;
const slotCheckPriceCode = __SLOTCHECK_PRICE_CODE__;
const slotcheckPriceCodeYearly = __SLOTCHECK_PRICE_CODE_YEARLY__;

export const LegacyStripeApp = () => {
  const service = new StripeService();
  const showToast = useToast();
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const {
    kpCasinoPk,
    priceCodes: { monthly: priceCodeMonthly, yearly: priceCodeYearly },
  } = useSelector<IState>((state) => state.app.persistedState.casinoSchema) as ICasinoSchema;
  const history = useHistory();
  const location = useLocation<ILocationWithPreviousPage>();
  const [previousPage, setPreviousPage, removePreviousPage] =
    useLocalStorage('authSlatePreviousPage');

  const [stripe, setStripe] = useState<IStripe>();
  const [checkedYearlyPeriod, setCheckedYearlyPeriod] = useState(false);

  useEffect(() => {
    setStripe(Stripe(__STRIPE_PK__));
  }, []);

  useEffect(() => {
    if (location.state?.previousPage) {
      setPreviousPage(location.state.previousPage);
    }
  }, [location.state]);

  const createSession = (priceId: string, couponCode: string) => {
    service.CreateCheckoutSession(priceId, couponCode, parseInt(slotCheckCasinoId)).subscribe({
      next(data) {
        if (stripe) {
          stripe.redirectToCheckout({
            sessionId: data?.id as string,
          });
        }
      },
      error(err) {
        showToast({ type: 'danger', message: err.message });
        console.error(err);
      },
    });
  };

  const goBack = () => {
    if (previousPage) {
      history.push(previousPage as string);
    } else {
      history.goBack();
    }

    removePreviousPage();
  };

  return (
    <IonPage className="app-parent">
      <div className="slotcheck-header">
        <IonHeader className="small-header">
          <IonToolbar>
            <IonButton fill="clear" onClick={goBack} className="icon-back" slot="start">
              <IonImg src="assets/images/back-icon.svg" />
            </IonButton>
            <div className="logo header-title">Legacy Slotcheck plan</div>
          </IonToolbar>
        </IonHeader>
      </div>
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className=" m-signup m-signup__product">
              <ProPlanLegacy
                onClickUpgrade={(couponCode) => {
                  createSession(String(slotCheckPriceCode), couponCode);
                }}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
