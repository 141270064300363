import { IonIcon } from '@ionic/react';
import { Button } from '@material-ui/core';
import { chevronForward, globe } from 'ionicons/icons';
import React from 'react';

interface CasinoCardProps {
  logoIcon: string;
  name: string;
  address: string;
  website: string;
  actionText: string;
  onActionClick: () => void;
  isComingSoon?: boolean;
}

export const CasinoCard: React.FC<CasinoCardProps> = ({
  isComingSoon = false,
  name,
  address,
  website,
  logoIcon,
  actionText,
  onActionClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(website, '_blank');
  };

  const onActionClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onActionClick();
  };

  return (
    <div className="casino-card">
      <div className="casino-logo">
        <img src={logoIcon} />
      </div>

      <div className="card-info">
        <div className="casino-info">
          <div>
            <h3 className="caption-text heading">{name}</h3>

            <p className="remove-margins location body-small">{address}</p>
          </div>

          <a
            onClick={handleClick}
            target="_blank"
            rel="noreferrer"
            className="link-website body-small"
          >
            <IonIcon className="icon" icon={globe} />
            Visit Website
          </a>
        </div>

        {isComingSoon ? (
          <div className="body-small action ">Coming Soon</div>
        ) : (
          <span
            onClick={onActionClickHandler}
            className="body-small-bold action link-player-id"
            data-testid="casinoCardAction"
          >
            {actionText}
            <IonIcon className="icon" icon={chevronForward} />
          </span>
        )}
      </div>
    </div>
  );
};
