/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { AuthService, ICasinoLinkedAccount, IUserInfo } from 'services/auth';
import { CasinoService } from 'services/casino';
import { PlayerService } from 'services/player';
import { FilterTypeEnum, checkProUser, heatLegendAllInsight } from 'common/common';

import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { ReduxCommandCreator } from 'appRedux/actions';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { IFilterState, IState } from 'appRedux/createStore';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';

import SmartLoading from 'components/SmartLoading';
import { CasinoCard } from 'components/CasinoCard.tsx/CasinoCard';
import { IUserSubscription } from '@interfaces/userSubscription';
import { useHistory } from 'react-router';
const slotCheckCasinoId = parseInt(__SLOTCHECK_ID__);

export const ChooseCasinos: FC = () => {
  let isMounted = true;
  const casinoSvc = new CasinoService();
  const auth = new AuthService();
  const playerSvc = new PlayerService();
  const history = useHistory();
  const router = useIonRouter();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const floormapCommand = FloormapReduxCommandCreator(dispatch);

  const { aliasName } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);
  const [casinos, setCasinos] = useState<ICasinoSchema[]>([]);
  const [comingSoonCasinos, setComingSoonCasinos] = useState<ICasinoSchema[]>([]);
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore) {
      setIsLoading(true);

      getUserAliasInformation(1);

      casinoSvc.GetAllCasinos().subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            const isActiveCasinos = casinoResponse.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
            );
            const isComingSoonCasinos = casinoResponse.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && casinoInfo.isComingSoon
            );

            setCasinos(isActiveCasinos);
            setComingSoonCasinos(isComingSoonCasinos);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino information.',
          }).catch(() => null);
        },
        complete() {
          setIsLoading(false);
        },
      });
    }

    return () => {
      isIgnore = true;
    };
  }, []);

  const updateUserPreference = (reqParam: IFilterState[] | undefined) => {
    if (reqParam === undefined) {
      filterCommandCreator.ResetAllFilters();
    } else {
      const filters = reqParam[0];

      if (filters && FilterTypeEnum && FilterTypeEnum.hottestSlots in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.hottestSlots, {
          ...filters[FilterTypeEnum.hottestSlots],
        });
      }

      if (filters && FilterTypeEnum && FilterTypeEnum.leaderboard in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.leaderboard, {
          ...filters[FilterTypeEnum.leaderboard],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.jackpot in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.jackpot, {
          ...filters[FilterTypeEnum.jackpot],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.myplay in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.myplay, {
          ...filters[FilterTypeEnum.myplay],
        });
      }
    }
  };

  const getUserAliasInformation = (casinoId: number) => {
    const playerSubscription = playerSvc.GetUserAlias(casinoId).subscribe({
      next(aliasResponse) {
        if (aliasResponse) {
          const { aliasInitial2, aliasInitial, aliasName } = aliasResponse.DataSet[0];

          const userAlias: Partial<IUserInfo> = {
            aliasInitial,
            aliasInitial2,
            aliasName,
          };

          commands.SetUserAlias(userAlias);
        }
      },
      error(err) {
        console.error(err);
      },
    });
    return () => {
      playerSubscription.unsubscribe();
    };
  };

  function findCasinoSubscription(
    casinoId: number,
    subscriptions: IUserSubscription[]
  ): IUserSubscription | null {
    const subscription = subscriptions.find(
      (subscription) =>
        subscription && typeof subscription === 'object' && subscription.casinoId === casinoId
    );
    return subscription || null;
  }

  const onClickCasino = (casinoDetail: ICasinoSchema) => {
    if (casinoDetail.isComingSoon) {
      return;
    }

    if (isMounted) {
      setIsLoading(true);
    }
    const { casinoTimezoneNew, casinoId } = casinoDetail;
    casinoCommands.SetCasinoSchema(casinoDetail);
    moment.tz.setDefault(casinoTimezoneNew);
    floormapCommand.SetDefaultHeatFilterAction(heatLegendAllInsight);
    auth.GetMe(casinoId).subscribe({
      next(data) {
        if (data?.user) {
          const { preference, linkedAccounts, hasAccess, timeLeft } = data.user;
          const { aliasInitial2, aliasInitial, aliasName, firstName, lastName, emailAddress } =
            data.user.info;

          const aliasInformation: Partial<IUserInfo> = {
            aliasInitial: aliasInitial,
            aliasInitial2: aliasInitial2,
            aliasName: aliasName,
          };

          getUserAliasInformation(casinoId);
          commands.SetTourState(data.user.info?.hasCompletedOnboarding as boolean);
          commands.SetUserPrivacy(data.user.info.isPublicVisible);
          commands.SetUserAlias({
            ...aliasInformation,
          });
          const userInformation: Partial<IUserInfo> = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
          };
          commands.SetUserInformation({
            ...userInformation,
          });

          if (data.user.subscriptions) {
            const subscription = findCasinoSubscription(slotCheckCasinoId, data.user.subscriptions);
            const legacySubscription = findCasinoSubscription(casinoId, data.user.subscriptions);

            if (subscription) {
              const { subscriptionData, customerData } = subscription;
              const customerId = customerData?.id;
              const planId = subscriptionData?.plan?.id;
              const subscriptionId = subscriptionData?.id;
              const currentPeriodEnd = subscriptionData?.current_period_end;
              const canceledAt = subscriptionData?.canceled_at;
              const subscriptionActive = subscriptionData?.status;
              const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);

              if (isProUser) {
                preference && updateUserPreference(preference as IFilterState[]);
              } else {
                updateUserPreference(undefined);
              }

              commands.SetUserLevel({
                level: planId,
                customer_id: customerId,
                subscription_id: subscriptionId,
                current_period_end: currentPeriodEnd,
                canceled_at: canceledAt,
                isProUser,
                appUserPk: data.user.info?.appUserPk,
              });
            } else {
              updateUserPreference(undefined);
              commands.SetUserLevel({
                level: '',
                customer_id: '',
                subscription_id: '',
                isProUser: false,
              });
              if (legacySubscription) {
                history.push('/auth/legacyStripe', {
                  previousPage: '../tabs/home',
                });
                return;
              }
            }
          } else {
            commands.SetUserLevel({
              level: '',
              customer_id: '',
              subscription_id: '',
              isProUser: false,
            });
          }

          if (!hasAccess) {
            history.push(`/auth/linkplayer/${casinoId}`, {
              previousPage: '/auth/choosecasinos',
            });
            return;
          }

          if (linkedAccounts && linkedAccounts.length > 0) {
            const casinoItem = linkedAccounts.find(
              (item: ICasinoLinkedAccount) => item.casinoId === casinoId
            );
            const isPlayerLinked = casinoItem ? true : false;
            if (isPlayerLinked) {
              const linkedCasinoId = casinoItem ? casinoItem?.casinoId : null;
              casinoCommands.SetLinkedCasinos({
                casinoLinkedAccounts: linkedAccounts,
                isPlayerLinked,
                linkedCasinoId,
              });
              router.push('/tabs/home');
            } else {
              router.push(`/auth/linkplayermodal/${casinoId}`);
            }
          } else {
            casinoCommands.SetLinkedCasinos({
              casinoLinkedAccounts: [],
              isPlayerLinked: false,
              linkedCasinoId: null,
            });
            router.push(`/auth/linkplayermodal/${casinoId}`);
          }
        }
      },
      error(err) {
        commands.SetUserInfo('', '');
        commands.SetAuthState(AUTH_STATE.SIGN_IN);
      },
      complete() {
        if (isMounted) {
          setIsLoading(false);
        }
      },
    });
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);
  const renderCasinoItem = (casino: ICasinoSchema, key: string) => {
    const { displayName, casinoId, logos, website, address } = casino;

    return (
      <IonRow data-testid="choose-casino-item" key={key}>
        <IonCol size="12">
          <CasinoCard
            key={casinoId?.toString()}
            actionText="Go to casino"
            logoIcon={logos.navBar}
            name={displayName}
            website={website}
            address={address.displayAddress}
            onActionClick={() => onClickCasino(casino)}
          />
        </IonCol>
      </IonRow>
    );
  };

  return (
    <IonPage className="app-parent">
      <IonContent>
        <SmartLoading loading={isLoading} />
        {!isLoading && (
          <div className="container">
            <IonGrid className="ion-no-margin m-signup m-signup__choose-casino">
              <div className="logo">
                <IonImg src="assets/images/SLOTcheckTM.svg" />
              </div>

              <div className="user-alias subtitle1">Welcome {aliasName}</div>

              <div className="choose-casinos my-casinos">
                <IonRow>
                  <IonCol>
                    <div className="header-bar">
                      <h1 className="heading5 heading">Choose a Casino</h1>
                    </div>
                  </IonCol>
                </IonRow>
                {(casinos || []).map((eachCasino, index) => {
                  return renderCasinoItem(eachCasino, `casino-key-${index}`);
                })}

                {comingSoonCasinos.length >= 1 && (
                  <div className="upcoming-casinos">
                    <h2 className="header body-bold">Coming Soon</h2>

                    {comingSoonCasinos?.map((casino, index) =>
                      renderCasinoItem(casino, `casino-soon-key-${index}`)
                    )}
                  </div>
                )}
              </div>
            </IonGrid>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
